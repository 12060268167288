@import "primeflex/primeflex.css";
@import "app/app.component.scss";
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import 'assets/css/scss/variables';

@font-face {
  font-family: 'Segoe UI Bold';
  src: url(/src/assets/fonts/Segoe-UI-Bold.ttf);
}

@font-face {
  font-family: 'Segoe UI';
  src: url(/src/assets/fonts/Segoe_UI.ttf);
}



/* Estilo da barra de rolagem */
::-webkit-scrollbar {
  width: 8px; 
  height: 8px; 
  // background-color: #09274B; 
}

/* Estilo do indicador de posição */
::-webkit-scrollbar-thumb {
  background-color: #09274B; 
  border-radius: 4px; 
}

/* Estilo do indicador de posição ao ser pressionado */
::-webkit-scrollbar-thumb:active {
  background-color: #9E9E9E; /* Cor do indicador de posição ao ser pressionado */
}


body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// body::before, body::after {
//   content: none;
// }

ol, ul{
  padding-left: 0;
}

.p-paginator-bottom.p-paginator.p-component {
  border-radius: 8px;
  background-color: #F1F1F1;
}

.searchField input {
  text-indent: 22px;
  border-radius: 8px;
}

input:focus-visible {
  outline: none;
}
.form-label {
  font: normal normal normal 15px / 23px Poppins;
  font-weight: 600;
  color: $dark-blue;
}


.filter-button button {
  background: transparent;
  color: black;
  border-color: black;
  border-radius: 10px;
}

.add-button button {
  width: 100%;
  background-color: #53EE5A;
  border: transparent;
  color: #09274B;
  border-radius: 2rem;
  height: 2.4rem;
}

.add-button button:enabled:hover {
  background: #84eb29;
  color: #09274B;
}

.blue button {
  border-color: #2196F3;
  color: #2196F3;
}

.border-10 .p-dropdown {
  border-radius: 10px;
}

.border-10 .p-dropdown-panel {
  border-radius: 10px;
}

 .modalUserForm .p-dialog-header{
  padding: 1rem;
}

 .p-dialog .p-dialog-header .p-dialog-title {
  font-family: Poppins;
  color: #09274B;
  font-size: 14px;
  font-weight: 500;
}

.modalUserForm .form-label{
  font: normal normal normal 14px / 24px Poppins;
  font-weight: 500;
  color: #37322C;
}

.p-button-cancel {
  border-radius: 18px;
  margin-right: 1rem;
  width: 20%;
  background: #FF3030;
  border-color: #FF3030;
}

.p-button-save{
  border-radius: 18px;
  width: 20%;
  background-color: #09274B;
  opacity: 1;
  border-color: #09274B;
}


.table-div,
.table-inf {
  background-color: transparent;
  border-radius: 8px;
  min-width: 100%;
  overflow: auto;
  width: 5rem;
}

.p-datatable .p-datatable-thead > tr > th{
  text-align: center;
  white-space: nowrap;
}

.p-datatable .p-datatable-tbody > tr > td{
  text-align: center;
}
.custom-spinner .p-progress-spinner-circle {
  animation: custom-progress-spinner-dash 1.5s ease-in-out infinite, custom-progress-spinner-color 6s ease-in-out infinite;
}

.p-button .p-button-label {
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 13px;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: #09274B;
  font: normal normal normal 14px/25px Segoe UI;
  color: #fff;
  border-radius: 20px;
}

.p-dialog .p-dialog-footer {
  text-align: center;
}

.p-button-filter{
  background: #09274B;
  border-color: #4E5066;
  border-radius: 2rem;
  color: #53EE5A;
  width: 10rem;
  text-align: center;
  opacity: 1;
}

.p-button-search{
  border-radius: 2rem;
  background: #53EE5A;
  border-color: #53EE5A;

  .pi-search{
    color: #09274B;
  }
}
@keyframes custom-progress-spinner-color {

  100%,
  0% {
    stroke: #16697A;
  }

  40% {
    stroke: #489FB5;
  }

  66% {
    stroke: #82C0CC;
  }

  80%,
  90% {
    stroke: #FFA62B;
  }
}

.spinner {
    width: 100vw;
    top: 0;
    position: absolute;
    left: 0;
    min-height: 100vh; 
    height: 100%; 
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

::placeholder, .p-dropdown .p-dropdown-label.p-placeholder{
  color: #CACBD5;
  font-family: 'Poppins';
  font-weight: 300;
  font-size: 14px;
}

.titleFilter, .titleCalendar{
  font-family: 'Poppins';
  font-weight: 600;
  color: #09274B;
  font-size: 15px;
}

input[type=date] {
  border: 1px solid #D6D8DB;
  border-radius: 10px;
  padding: 0.5em 0.5rem 0.5rem;
  color: #CACBD5;
  font-family: 'Poppins';
  font-weight: 300;
}

@keyframes custom-progress-spinner-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

